import { Component, Input, OnInit, viewChild, inject, input } from '@angular/core';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { SapApiService } from 'src/app/services/sap-api.service';
import { SapOutage } from 'src/app/model/sap/sap-outage';
import { ActionsDropdownComponent } from 'src/app/crm/containers/controller-notes/actions-dropdown/actions-dropdown.component';
import { Subscription } from 'rxjs';
import { TabService } from 'src/app/core/services/tab.service';
import { MOC_EVENT_DETAILS_CONTAINER } from 'src/app/constants/common.constants';
import { MocEventBuilderService } from 'src/app/services/moc-event-builder.service';
import { MocEventTableService } from 'src/app/services/moc-event-table.service';
import { CLAIMS } from 'src/app/constants/auth-constants';

@Component({
    selector: 'app-read-only-sap-view',
    templateUrl: './read-only-sap-view.component.html',
    styleUrls: ['./read-only-sap-view.component.scss'],
    standalone: false
})
export class ReadOnlySapViewComponent implements OnInit {
  private _authApi = inject(AuthApiService);
  private sapApiService = inject(SapApiService);
  private _mocBuilder = inject(MocEventBuilderService);
  private _mocTable = inject(MocEventTableService);

  readonly actionsDropdownChild = viewChild.required<ActionsDropdownComponent>('actions');

  isLoading = false;
  selectedOutage: SapOutage;
  canCreateMOCEvent = false;
  tabClosingSub: Subscription;

  availableActions: any[] = [];

  validTaskCodes = ['ONSC', 'ONPC'];

  readonly id = input<string>(undefined);
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() notifNum: string;

  ngOnInit(): void {
    this.setupTabClosingSubscription();

    const id = this.id();
    if (id) {
      this.loadData(id);
    }

    this.canCreateMOCEvent = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.CONTROL_ROOM.MOC_EVENTS.CREATE_MOC_EVENTS,
    ]);

    this.fillActionsDropdown();
  }

  loadData(id: string): void {
    this.isLoading = true;
    this.sapApiService.getSapOutage(id).subscribe((outage: SapOutage): any => {
      this.selectedOutage = outage;
      this.isLoading = false;
    }),
      (error) => console.error(error);
  }

  fillActionsDropdown() {
    this.availableActions = [];
    if (this.canCreateMOCEvent) {
      this.availableActions.push({ label: 'Create MOC Event' });
    }
  }

  onActionSelected(action: string) {
    setTimeout(() => {
      this.actionsDropdownChild().clear();
    }, 100);
    if (action === 'Create MOC Event') {
      const mocEvent = this._mocBuilder.buildMOCEventFromSapOutage(
        this.selectedOutage
      );
      this._mocTable.setSelected(mocEvent);

      const mocTab = TabService.getInstance().buildNewTab(
        MOC_EVENT_DETAILS_CONTAINER,
        true
      );
      TabService.getInstance().openTab(mocTab);
    }
  }

  setupTabClosingSubscription(): void {
    this.tabClosingSub = TabService.getInstance().attemptCloseEvent.subscribe(
      (closingTab) => {
        TabService.getInstance().closeTab(closingTab.index);
      }
    );
  }
}
