<div class="container">
  @if (!isLoading && selectedOutage) {
  <div class="center">
    <div class="header">
      <app-actions-dropdown
        class="right-align"
        [availableActions]="availableActions"
        (actionSelected)="onActionSelected($event)"
        [ngStyle]="{ display: 'inline' }"
        #actions
        appendTo="body"
      ></app-actions-dropdown>
    </div>

    <div class="info-form">
      <h2 class="card-header">
        {{ "REPORTING.LABEL.SAP_OUTAGE_DETAILS" | translate }}
      </h2>

      <div class="row-grouping">
        <div class="row">
          <div class="row-item">
            {{ "REPORTING.LABEL.START" | translate }}:
            {{ selectedOutage.reqStart | date : "MM/dd/yyyy HH:mm:ss" }}
          </div>

          <div class="row-item">
            {{ "REPORTING.LABEL.END" | translate }}:
            {{ selectedOutage.reqEnd | date : "MM/dd/yyyy HH:mm:ss" }}
          </div>

          <div class="row-item">
            {{ "REPORTING.LABEL.TITLE" | translate }}:
            {{ selectedOutage.shortDesc }}
          </div>
        </div>
        <div class="row">
          @if (selectedOutage.Tasks[0]?.taskText && selectedOutage.longDesc) {
          <div class="row-item-long">
            {{ "REPORTING.LABEL.DESCRIPTION" | translate }}:
            {{
              selectedOutage.Tasks[0].taskText + " - " + selectedOutage.longDesc
            }}
          </div>
          } @else if (selectedOutage.Tasks[0]?.taskText) {
          <div class="row-item-long">
            {{ "REPORTING.LABEL.DESCRIPTION" | translate }}:
            {{ selectedOutage.Tasks[0].taskText }}
          </div>
          } @else if (selectedOutage.longDesc) {
          <div class="row-item-long">
            {{ "REPORTING.LABEL.DESCRIPTION" | translate }}:
            {{ selectedOutage.longDesc }}
          </div>
          }
        </div>
        <div class="break"></div>
      </div>
    </div>

    <p-divider></p-divider>

    @if (selectedOutage.Facility) {
    <div class="info-form">
      <h2 class="card-header">
        {{ "REPORTING.LABEL.FACILITY_INFO" | translate }}
      </h2>
      <div class="row">
        <div class="row-item">
          {{ "REPORTING.LABEL.FACILITY_NAME" | translate }}:
          {{ selectedOutage.Facility.facilityName }}
        </div>

        <div class="row-item">
          {{ "REPORTING.LABEL.FACILITY_ID" | translate }}:
          {{ selectedOutage.Facility.facilityUid }}
        </div>

        <div class="row-item">
          {{ "REPORTING.LABEL.FLOC" | translate }}:
          {{ selectedOutage.Facility.flocNumber }}
        </div>
      </div>
    </div>
    }

    <p-divider></p-divider>

    <div class="info-form">
      <h2 class="card-header">{{ "REPORTING.LABEL.TASKS" | translate }}</h2>
      <div class="row-grouping">
        @for (task of selectedOutage.Tasks; track $index) { @if ((task.taskText
        || task.longText) && validTaskCodes.includes(task.taskCode)) {
        <div class="outage-task">
          <div class="row">
            <div class="row-item">
              {{ "REPORTING.LABEL.TASK_CODE" | translate }}: {{ task.taskCode }}
            </div>
          </div>
          <div class="row">
            @if (task.taskText && task.longText) {
            <div class="row-item-long">
              {{ "REPORTING.LABEL.DESCRIPTION" | translate }}:
              {{
                task.taskText && task.longText
                  ? task.taskText + " - " + task.longText
                  : ""
              }}
            </div>
            } @else if (task.taskText) {
            <div class="row-item-long">
              {{ "REPORTING.LABEL.DESCRIPTION" | translate }}:
              {{ task.taskText }}
            </div>
            } @else if (task.longText) {
            <div class="row-item-long">
              {{ "REPORTING.LABEL.DESCRIPTION" | translate }}:
              {{ task.longText }}
            </div>
            }
          </div>
        </div>
        } }
      </div>
    </div>

    <p-divider></p-divider>

    <div class="info-form">
      <h2 class="card-header">{{ "REPORTING.LABEL.CAUSES" | translate }}</h2>
      <div class="row-grouping">
        @for (cause of selectedOutage.Causes; track cause.id) {
        <div class="outage-cause">
          <div class="row">
            <div class="row-item">
              {{ "REPORTING.LABEL.ISSUE_TEXT" | translate }}:
              {{ cause.issueText }}
            </div>
          </div>
          <div class="row">
            <div class="row-item">
              {{ "REPORTING.LABEL.CAUSE_CODE_GROUP" | translate }}:
              {{ cause.causeCodeGroup }}
            </div>
          </div>
          <div class="row">
            <div class="row-item">
              {{ "REPORTING.LABEL.CAUSE_CODE" | translate }}:
              {{ cause.causeCode }}
            </div>
          </div>
          <div class="row">
            <div class="row-item">
              {{ "REPORTING.LABEL.CAUSE_CODE_TEXT" | translate }}:
              {{ cause.causeCodeText }}
            </div>
          </div>
          <div class="row">
            <div class="row-item">
              {{ "REPORTING.LABEL.CAUSE_TEXT" | translate }}:
              {{ cause.causeText }}
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
  } @if (isLoading) {
  <p-progressSpinner class="spinner"></p-progressSpinner>
  }
</div>
