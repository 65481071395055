import { ChangeDetectorRef, Component, viewChild, inject } from '@angular/core';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { SAP_OUTAGE_TABLE_DEFINITION } from '../definitions/sap-outage-table-definition';
import { DeviceService } from 'src/app/services/device.service';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { TabService } from 'src/app/core/services/tab.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { BusinessUnitFilterComponent } from 'src/app/core/components/business-unit-filter/business-unit-filter.component';
import { SelectItem } from 'primeng/api/selectitem';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { take } from 'rxjs';
import { dynamicSort } from 'src/app/utils/utils';
import { ReportingApiService } from 'src/app/services/reporting-api.service';
import { ReportSapOutage } from 'src/app/model/reporting/report-sap-outage';
import { ReportSapOutageTableService } from 'src/app/services/report-sap-outage-table.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { GeneralGridComponent } from 'src/app/core/components/general-grid.component';
import { AorApiService } from 'src/app/services/aor-api.service';
import { AOR } from 'src/app/model/locations/aor';
import { ReadOnlySapViewComponent } from '../components/read-only-sap-view/read-only-sap-view.component';
import { SapOutage } from 'src/app/model/sap/sap-outage';
import { SapApiService } from 'src/app/services/sap-api.service';

@Component({
    selector: 'app-sap-outage-report',
    templateUrl: './sap-outage-report.component.html',
    styleUrl: './sap-outage-report.component.scss',
    standalone: false
})
export class SapOutageReportComponent extends SearchContainerComponent<ReportSapOutage> {
  protected _deviceService: DeviceService;
  protected _auth: AuthService;
  protected _authApi = inject(AuthApiService);
  protected _translateService = inject(TranslateService);
  protected _logAndMessage = inject(LogAndMessageService);
  protected _tabService = inject(TabService);
  protected _cdRef: ChangeDetectorRef;
  protected _loader: LoadingService;
  protected _contactApi = inject(ContactApiService);
  private _aorAPI = inject(AorApiService);
  protected _reportingApi = inject(ReportingApiService);
  protected _reportSapOutageTableService = inject(ReportSapOutageTableService);
  private _sapApi = inject(SapApiService);

  readonly businessUnitFilter = viewChild<BusinessUnitFilterComponent>('buFilter');
  readonly generalGrid = viewChild<GeneralGridComponent>('grid');
  readonly readOnlyDetailedView = viewChild<ReadOnlySapViewComponent>('detailedView');

  detailDialogVisible: boolean = false;
  outagesLoading: boolean = false;
  startDate: Date;
  endDate: Date;

  selectedBusinessUnits: string[];

  aors: SelectItem[];
  selectedAORs: SelectItem[];

  availableOutageGroups: SelectItem[];
  selectedOutageGroups: SelectItem[];

  selectedOutage: SapOutage;

  isFirstLoad = true;

  todaySelected = false;
  tomorrowSelected = false;
  sevenDaysSelected = false;
  deratesSelected = false;
  pipelineMaintenanceSelected = false;

  private _isRefreshing = false;

  constructor() {
    const _deviceService = inject(DeviceService);
    const _auth = inject(AuthService);
    const _cdRef = inject(ChangeDetectorRef);
    const _loader = inject(LoadingService);

    super(_deviceService, _loader, _auth, _cdRef);
    this._deviceService = _deviceService;
    this._auth = _auth;
    this._cdRef = _cdRef;
    this._loader = _loader;

  }

  ngOnInit() {
    this.setTableService(this._reportSapOutageTableService);
    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initialize();
      });
    }
  }

  initialize() {
    this.columns = SAP_OUTAGE_TABLE_DEFINITION;
    this.elements = [];

    this.startDate = new Date();

    this.populateAors();

    this._sapApi.getOutageGroups().subscribe((data) => {
      this.availableOutageGroups = data
        .map((c) => ({
          label: c.name,
          value: c.CompanyCodes.map((c) => c.companyCode),
        }))
        .sort(dynamicSort('label', 1));

      this.refresh();
    });
  }

  handleLazyLoad(req, $event, filters) {
    if (!this.startDate) {
      this.startDate = new Date();
    }

    const query = {
      reqStart: this.startDate
        ? this.createUTCDateEquivalent(this.startDate)
        : null,
      reqEnd: this.endDate ? this.createUTCDateEquivalent(this.endDate) : null,
      notifNum: filters.notifNum ? filters.notifNum.value.trim() : null,
      flocNumber: filters.flocNumber ? filters.flocNumber.value.trim() : null,
      funcLocDesc: filters.funcLocDesc
        ? filters.funcLocDesc.value.trim()
        : null,
      shortDesc: filters.shortDesc ? filters.shortDesc.value.trim() : null,
      taskText: filters.taskText ? filters.taskText.value.trim() : null,
      longText: filters.longText ? filters.longText.value.trim() : null,
      companyCodes: this.selectedOutageGroups
        ? this.selectedOutageGroups.flat()
        : null,
      aors: this.selectedAORs ? this.selectedAORs : null,
      useDefaultDateRange: this.isFirstLoad,
      derates: this.deratesSelected,
      pipelineMaintenance: this.pipelineMaintenanceSelected,
    };

    this.isFirstLoad = false;

    this._reportSapOutageTableService.setLastQuery(query);
    this.querySapOutages(req, $event, query);
  }

  createUTCDateEquivalent(date: Date): Date {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  }

  private querySapOutages(req, $event, query) {
    const sort = {};
    if ($event.lazy && $event.lazy.sortField) {
      let field = $event.lazy.sortField;

      sort[field] =
        $event.lazy.sortOrder && $event.lazy.sortOrder === 1 ? 'DESC' : 'ASC';
      this._reportSapOutageTableService.setLastSortField($event.lazy.sortField);
      this._reportSapOutageTableService.setLastSortDirection(
        $event.lazy.sortOrder
      );
    } else {
      sort['reqStart'] = 'ASC';
      this._reportSapOutageTableService.setLastSortField('reqStart');
      this._reportSapOutageTableService.setLastSortDirection(0);
    }

    if (!this._isRefreshing) {
      this._isRefreshing = true;
      this._reportingApi.queryForSapOutages(query, sort).subscribe({
        next: (data: ReportSapOutage[]) => {
          this.elements = [...data];
          this.totalRecords = this.elements.length;
          this.loading = false;
          this._isRefreshing = false;
        },
        error: (error) => {
          console.log(error);
          this.loading = false;
          this._isRefreshing = false;
        },
      });
    }
  }

  dateUpdate(fromEnd = false) {
    this.clearSelectedButtons();

    if (this.endDate < this.startDate && fromEnd === false) {
      this.endDate = new Date(this.startDate.valueOf());
      this.endDate.setDate(this.endDate.getDate() + 1);
    } else if (this.endDate < this.startDate && fromEnd === true) {
      this.startDate = new Date(this.endDate.valueOf());
      this.startDate.setDate(this.startDate.getDate() - 1);
    }

    this.refresh();
  }

  populateAors() {
    this.aors = [];
    this._aorAPI
      .getAORs(this.selectedBusinessUnits)
      .subscribe((aors: AOR[]) => {
        this.aors = [
          ...aors
            .map((c) => ({
              label: c.name,
              value: c.id,
            }))
            .sort(dynamicSort('label', 1)),
        ];
      });
  }

  handleBusinessUnitChange() {
    this.selectedAORs = [];

    this.getBusinessUnits(this.businessUnitFilter().selectedBusinessUnits);
    this.refresh();
  }

  handleAOROrCompanyChange() {
    this.clearSelectedButtons();

    this.refresh();
  }

  getBusinessUnits(data: any) {
    this.selectedBusinessUnits = data;
    // need to delay this a tiny amount so that the selected business units update before we query
    setTimeout(() => {
      this.populateAors();
    });

    this.refresh();
  }

  todayClick() {
    this.todaySelected = true;
    this.tomorrowSelected = false;
    this.sevenDaysSelected = false;
    this.deratesSelected = false;
    this.pipelineMaintenanceSelected = false;

    let now = new Date();

    this.startDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,
      0,
      0
    );
    this.endDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59
    );

    this.refresh();
  }

  tomorrowClick() {
    this.tomorrowSelected = true;
    this.sevenDaysSelected = false;
    this.deratesSelected = false;
    this.pipelineMaintenanceSelected = false;
    this.todaySelected = false;

    let now = new Date();
    let tomorrow = new Date(now.setDate(now.getDate() + 1));

    this.startDate = new Date(
      tomorrow.getFullYear(),
      tomorrow.getMonth(),
      tomorrow.getDate(),
      0,
      0,
      0
    );
    this.endDate = new Date(
      tomorrow.getFullYear(),
      tomorrow.getMonth(),
      tomorrow.getDate(),
      23,
      59,
      59
    );

    this.refresh();
  }

  sevenDayClick() {
    this.sevenDaysSelected = true;
    this.deratesSelected = false;
    this.pipelineMaintenanceSelected = false;
    this.todaySelected = false;
    this.tomorrowSelected = false;

    let nowToModify = new Date();
    let sevenDaysLater = new Date(
      nowToModify.setDate(nowToModify.getDate() + 7)
    );

    let now = new Date();

    this.startDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,
      0,
      0
    );
    this.endDate = new Date(
      sevenDaysLater.getFullYear(),
      sevenDaysLater.getMonth(),
      sevenDaysLater.getDate(),
      23,
      59,
      59
    );

    this.refresh();
  }

  deratesClick() {
    this.deratesSelected = true;
    this.pipelineMaintenanceSelected = false;
    this.todaySelected = false;
    this.tomorrowSelected = false;
    this.sevenDaysSelected = false;

    this.refresh();
  }

  pipelineMaintenanceClick() {
    this.pipelineMaintenanceSelected = true;
    this.deratesSelected = false;
    this.todaySelected = false;
    this.tomorrowSelected = false;
    this.sevenDaysSelected = false;

    this.refresh();
  }

  clearScreen() {
    this.clearSelectedButtons();

    this.isFirstLoad = true;
    this.selectedOutageGroups = [];
    this.selectedAORs = [];

    this.lazyLoad({ lazy: {} });
    this.grid().resetTable();
    this._reportSapOutageTableService.clearResults();
    this.elements = [];
  }

  clearSelectedButtons() {
    this.todaySelected = false;
    this.tomorrowSelected = false;
    this.sevenDaysSelected = false;
    this.deratesSelected = false;
    this.pipelineMaintenanceSelected = false;
  }

  refresh(): void {
    this.lazyLoad({
      lazy: this._reportSapOutageTableService.getLastLazyLoad(),
    });
  }

  exportToCSV(): void {
    this.generalGrid().exportCSVFunction();
  }

  getExportFileName() {
    return `SAP Outages Report - ${new Date().toLocaleDateString()}`;
  }

  openDetailedOutage($event: any) {
    this.selectedOutage = $event.data;

    const readOnlyDetailedView = this.readOnlyDetailedView();
    if (readOnlyDetailedView) {
      this.detailDialogVisible = true;

      readOnlyDetailedView.notifNum = $event.data.notifNum;
      readOnlyDetailedView.loadData(this.selectedOutage.id);
    }
  }
}
